<template>
  <label>
    <input
      :id="name"
      v-model="innerValue"
      class="hidden"
      type="checkbox"
      :name="name"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
    >
    <div :class="`cursor-pointer w-4 h-4 border-gray-400 border-1 flex items-center justify-center bg-white ${innerValue ? 'checked' : ''}`">
      <font-awesome-icon
        v-if="innerValue"
        class="text-white scale-75"
        icon="check"
      />
    </div>
  </label>
</template>

<script setup>
import useFormControl from '@shared/hooks/form/formControl'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Label name
  label: {
    type: String,
    default: null,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })
</script>

<style scoped>
.checked {
  @apply border-theme-500 bg-theme-500;
}
</style>
