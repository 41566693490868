<template>
  <app-overlay
    :show="confirmModalDisplayed"
    @clicked="handleClose"
  >
    <app-modal
      @closed="handleClose"
    >
      <p>{{ t('cookies.modal_warning.line_1') }}</p>

      <router-link
        :to="{ name: 'show-cookies' }"
      >
        <app-button
          class="mt-8"
          label="Modifier mes choix"
        />
      </router-link>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppButton from '../ui/AppButton.vue'

const { t } = useI18n()
const confirmModalDisplayed = ref(false)

// ---------- MODAL ----------

function handleClose() {
  confirmModalDisplayed.value = false
}

// ---------- LIFECYCLE ----------

onMounted(() => {
  confirmModalDisplayed.value = true
})
</script>
