<template>
  <app-button
    v-if="canGiveTip"
    :label="t('common.actions.give_a_tip')"
    :class="`${style} w-fit`"
    background-classes="bg-white"
    emphasis="medium"
    @click="handleTipClick"
  />

  <app-overlay
    :show="tipFormDisplayed"
    @clicked="handleTipClose"
  >
    <app-modal @closed="handleTipClose">
      <the-tip-form
        :payee-resource="resource"
        :mode="tipFormMode"
        :additional-errors="tipFormErrors"
        :submitting="tipFormSubmitting"
        @submitted="handleTipSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

import { createCheckoutSession } from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import TheTipForm from '@shared/components/features/tips/TheTipForm.vue'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Tip permission
  canGiveTip: {
    type: Boolean,
    default: false,
  },
  // CSS style
  style: {
    type: String,
    default: 'absolute -bottom-4 left-1/2 -translate-x-1/2',
  },
  // Mode used to display form
  tipFormMode: {
    type: String,
    required: true,
    validator(value) {
      // The value must match one of these strings
      return [
        'app',
        'extranet',
      ].includes(value)
    },
  },
})

const { t } = useI18n()

// ---------- TIP ----------

const tipFormDisplayed = ref(false)
const tipFormErrors = ref({})
const tipFormSubmitting = ref(false)

function handleTipClick() {
  tipFormDisplayed.value = true
}

function handleTipClose() {
  tipFormDisplayed.value = false
}

function handleTipSubmit(attributes) {
  tipFormSubmitting.value = true

  const params = {
    data: {
      attributes: { ...attributes },
    },
  }

  createCheckoutSession(params)
    .then((response) => {
      window.location.href = response.data.link
      handleTipClose()
    })
    .catch((e) => {
      if (e.response?.data?.errors) {
        tipFormErrors.value = e.response.data.errors
      }
    })
    .finally(() => {
      tipFormSubmitting.value = false
    })
}
</script>
