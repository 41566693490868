<template>
  <input
    :id="name"
    v-model="innerValue"
    :class="`form-control ${classes ? classes : ''}`"
    :autocomplete="autocomplete"
    type="time"
    :required="required"
    :placeholder="capitalize(placeholder)"
    :name="name"
    :readonly="readonly"
    @focus="handleFocus(true)"
    @blur="handleFocus(false)"
  >
</template>

<script setup>
import { capitalize } from 'lodash'

import useFormControl from '@shared/hooks/form/formControl'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Placeholder HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Readonly HTML attribute
  readonly: {
    type: Boolean,
    default: false,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // Autocomplete HTML attribute
  autocomplete: {
    type: String,
    default: null,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })
</script>
