<template>
  <img
    src="/images/shared/icons/tourist_office.jpg"
    :class="[width, position, top, right].join(' ')"
    class="rounded-full"
    alt="Tourist office picto"
  >
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // Icon width
  width: {
    type: String,
    default: 'w-6',
  },
  // Display mode
  mode: {
    type: String,
    default: 'card',
    validator(value) {
      return [
        'card',
        'show',
      ].includes(value)
    },
  },
})

// ---------- STYLE ----------

const isCardMode = computed(() => (
  props.mode === 'card'
))

const position = computed(() => (
  isCardMode.value ? 'absolute' : 'relative'
))

const top = computed(() => (
  isCardMode.value ? 'top-2' : 'top-0'
))

const right = computed(() => (
  isCardMode.value ? 'right-2' : 'right-0'
))
</script>
