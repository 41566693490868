<template>
  <div :class="gridStyle">
    <div
      v-for="(item, i) in list"
      :key="i"
      :class="verticalMargins"
    >
      <app-card-item
        height-classes="h-full"
        :shadow-classes="shadowClasses"
      >
        <component
          :is="component"
          v-bind="item"
        />
      </app-card-item>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppCardItem from './AppCardItem.vue'

const props = defineProps({
  // Props needed for each rendered components
  list: {
    type: Array,
    default: () => [],
  },
  // Vue Component that will be
  // rendered in the Card Component
  component: {
    type: Object,
    default: () => ({}),
  },
  // Vertical margins
  // between cards
  yMargins: {
    type: String,
    default: 'my-4',
  },
  // Display list in grid?
  gridMode: {
    type: Boolean,
    default: false,
  },
  // Grid cols classes
  gridCols: {
    type: String,
    default: 'lg:grid-cols-2 grid-cols-1',
  },
  // Grid gap
  gridGap: {
    type: String,
    default: 'gap-4',
  },
  // CSS for App Card Item shadow
  shadowClasses: {
    type: String,
    default: 'shadow-lg shadow-theme-500/10',
  },
})

// ---------- GRID ----------

const gridStyle = computed(() => {
  if (!props.gridMode) {
    return ''
  }

  // Adding grid classes from props
  const classes = ['grid']
  classes.push(props.gridCols)
  classes.push(props.gridGap)

  return classes.join(' ')
})

// ---------- MARGINS ----------

const verticalMargins = computed(() => (
  !props.gridMode
    ? props.yMargins
    : ''
))
</script>
