<template>
  <div
    :class="`flex bg-${bgColor}-200 text-left rounded-2lg`"
  >
    <div
      :class="`w-16 bg-${bgColor}-400 rounded-l-2lg`"
    >
      <div class="p-4">
        <font-awesome-icon
          class="text-white"
          size="2x"
          :icon="faIcon"
        />
      </div>
    </div>

    <div class="w-full items-center p-4">
      <span
        v-if="title"
        class="font-bold pb-4"
      >
        {{ title }}
      </span>

      <span class="block">
        <slot />
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // Determine some classes
  // (bg-color) applied
  // to the button
  // (e.g.: primary, danger, success)
  design: {
    type: String,
    default: null,
  },
  // Title of the alert
  title: {
    type: String,
    default: null,
  },
})

const bgColor = computed(() => {
  let color

  switch (props.design) {
    case 'success':
      color = 'green'
      break
    case 'danger':
      color = 'red'
      break
    case 'warning':
      color = 'yellow'
      break
    case 'info':
      color = 'blue'
      break
    default:
      color = 'gray'
  }

  return color
})

const faIcon = computed(() => {
  let icon

  switch (props.design) {
    case 'success':
      icon = 'check-circle'
      break
    case 'danger':
      icon = 'times-circle'
      break
    case 'warning':
      icon = 'exclamation-triangle'
      break
    case 'info':
      icon = 'info-circle'
      break
    default:
      icon = 'question-circle'
  }

  return icon
})
</script>
