/**
 * Base on luxon package:
 * https://moment.github.io/luxon/#/?id=luxon
 */

import i18n from '@shared/i18n/vue-i18n'
import { DateTime } from 'luxon'

/**
 * Format a date with short format:
 *  en: 10/14/1983
 *  fr: 14/10/1983
 *
 * @param date
 * @returns {string}
 */
export const formatDateShort = (date) => (
  formatDateTime(date, DateTime.DATE_SHORT)
)

/**
 * Format a date with abbreviated format:
 *  en: Oct 14, 1983
 *  fr: 14 oct. 1983
 *
 * @param date
 * @returns {string}
 */
export const formatDateAbbrev = (date) => (
  formatDateTime(date, DateTime.DATE_MED)
)

/**
 * Format a date with full format:
 *  en: October 14, 1983
 *  fr: 14 octobre 1983
 *
 * @param date
 * @returns {string}
 */
export const formatDateFull = (date) => (
  formatDateTime(date, DateTime.DATE_FULL)
)

/**
 * Format a time in simple format
 *  en: 1:30 PM
 *  fr: 13:30
 *
 * @param date
 * @returns {string}
 */
export const formatTimeSimple = (date) => (
  formatDateTime(date, DateTime.TIME_SIMPLE)
)

/**
 * Format a time in a format with seconds
 *  en: 1:30:23 PM
 *  fr: 13:30:23
 *
 * @param date
 * @returns {string}
 */
export const formatTimeWithSeconds = (date) => (
  formatDateTime(date, DateTime.TIME_WITH_SECONDS)
)

/**
 * Format a datetime in a format date time
 *  en: 12/1/2022 1:30:23 PM
 *  fr: 1/12/2022 13:30:23
 *
 * @param date
 * @returns {string}
 */
export const formatDateWithTime = (date) => (
  `${formatDateShort(date)} ${formatDateTime(date, DateTime.TIME_WITH_SECONDS)}`
)

/**
 * Format a dateTime
 *
 * @param dateTime
 * @param format
 * @returns {string}
 */
export const formatDateTime = (dateTime, format) => (
  DateTime
    .fromISO(dateTime)
    .setLocale(i18n.global.locale.value)
    .toLocaleString(format)
)

/**
 * Get current date in standard format
 *
 * @param days
 * @returns {string}
 */
export const currentDate = (days = 0) => (
  DateTime.now()
    .plus({ days })
    .toFormat('yyyy-MM-dd')
)

/**
 * Get current ISO datetime
 *
 * @param days
 * @returns {string}
 */
export const currentIsoDateTime = (days = 0) => (
  DateTime.now()
    .plus({ days })
    .toISO()
    .split('.')[0]
)

/**
 * Add days to given date
 *
 * @param date
 * @param days
 * @returns {string}
 */
export const addDaysToDate = (date, days) => (
  DateTime.fromISO(date)
    .plus({ days })
    .toISODate()
)

/**
 * Add days to given date
 *
 * @param endDate
 * @param startDate
 * @returns {object}
 */
export const diffInDays = (endDate, startDate) => (
  // Get days difference between 2 dates
  // format is { 'days': x }
  DateTime.fromISO(endDate)
    .diff(
      DateTime.fromISO(startDate),
      'days',
    ).toObject()
)
