<template>
  <teleport-wrapper to="body">
    <transition
      appear
      name="fade"
    >
      <!-- Overlay background -->
      <div
        v-if="show"
        id="overlay"
        :class="`h-full w-full flex flex-col items-center justify-center px-4 fixed top-0 bg-theme-500/30 left-0 z-${zIndex}`"
        @click.self="handleClick"
      >
        <!-- Overlay content -->
        <div
          class="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <slot />
        </div>
      </div>
    </transition>
  </teleport-wrapper>
</template>

<script setup>
defineProps({
  // Value used for the z index class
  zIndex: {
    type: String,
    default: '50',
  },
  // Show or not the overlay
  show: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'clicked',
  'closed', // Avoid warning (must refactor with provide/inject in all modals)
])

function handleClick() {
  emits('clicked')
}
</script>
