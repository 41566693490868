<template>
  <app-card-item
    class="p-4"
  >
    <div class="mb-6">
      <template
        v-if="resource?.relationships?.point_of_interest"
      >
        <point-of-interest-folder-card
          :resource="resource.relationships.point_of_interest"
        />
      </template>
    </div>

    <div class="flex items-center justify-between">
      <router-link
        v-if="butler"
        :to="{ name: 'show-users', params: { id: butler.id } }"
        class="flex items-center w-fit"
      >
        <app-rounded-picture
          :picture-url="avatarUrl"
          picture-size="14"
          icon-name="user"
          icon-size="lg"
        />

        <p
          class="font-bold ml-3 break-words w-28"
        >
          {{ butlerDisplayName ?? t('traveller_folders.placeholders.butler') }}
        </p>
      </router-link>

      <!-- Tip -->
      <div
        v-if="canGiveTip"
        class="flex justify-center"
      >
        <app-tip-button
          :resource="butler"
          :can-give-tip="canGiveTip"
          tip-form-mode="app"
          :style="null"
        />
      </div>
    </div>
  </app-card-item>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppTipButton from '@shared/components/features/tips/AppTipButton.vue'
import PointOfInterestFolderCard from '@app/components/resources/point_of_interest/PointOfInterestFolderCard.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { getImageUrlFromResource } = useResource()
const { t } = useI18n()

// ---------- BUTLER ----------

const butler = computed(() => (
  props.resource.relationships.butler
))

const {
  displayName: butlerDisplayName,
} = useUser({ resource: butler.value })

const avatarUrl = computed(() => (
  getImageUrlFromResource(butler.value, 'xs')
))

// ---------- TIP ----------

// Can give tip if butler can receive payments
const canGiveTip = computed(() => (
  butler.value?.attributes?.can_receive_payments
    && butler.value?.attributes?.tips_enabled
))
</script>
