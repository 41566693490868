import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default function useOnline() {
  const store = useStore()
  const { t } = useI18n()

  const offline = computed(() => (store.state.online ? !store.state.online.active : false))

  function displayConnectionFlash() {
    store.commit('flashes/ADD_FLASH', { message: t('common.online.disconnected'), type: 'danger' })
  }

  return {
    offline,
    displayConnectionFlash,
  }
}
