<template>
  <!--
    - v-if : null values are not accepted, so slider has display condition
    - style : reduce the width a little, to avoid the tooltip to overflow at max value
  -->
  <slider
    v-if="innerValue"
    v-model="innerValue"
    :min="min"
    :max="max"
    :format="formatSliderTooltips"
    style="width: 92%;"
  />
</template>

<script setup>
import Slider from '@vueform/slider'

import useFormControl from '@shared/hooks/form/formControl'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // Min HTML attribute
  min: {
    type: Number,
    default: null,
  },
  // Max HTML attribute
  max: {
    type: Number,
    default: null,
  },
  // Function to format
  // the tooltip
  formatSliderTooltips: {
    type: Function,
    default: () => {},
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const { innerValue } = useFormControl(props, { emits })
</script>
