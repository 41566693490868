<template>
  <div
    :class="`${classes} form-group`"
  >
    <div :class="`${wrapperClasses}`">
      <div :class="`relative flex flex-1-min-w-0 flex-${flexDirection} ${flexDirection === 'row' ? 'items-center' : justifyContent}`">
        <!-- Form Control -->
        <component
          :is="formControlComponent"
          v-if="formControlComponent"
          ref="formControlRef"
          v-bind="formControlPropsToUse"
          v-on="formControlEvents"
        >
          <template
            v-for="(slot, slotName) in $slots"
            #[slotName]="slotProps"
          >
            <slot
              v-if="slotName.startsWith('control-')"
              :name="slotName"
              v-bind="slotProps"
            />
          </template>
        </component>

        <!-- Label -->
        <!-- Use slot, or generated label -->
        <template v-if="$slots.label">
          <slot name="label" />
        </template>

        <div
          v-else-if="type !== 'hidden' && labelToUse"
          class="flex flex-start items-center"
        >
          <form-label
            v-bind="formLabelPropsToUse"
            :class="flexDirection === 'row' ? 'ml-4' : null"
            :type="type"
          />

          <app-help-button
            v-if="$slots['help-button']"
            class="ml-2"
          >
            <slot
              name="help-button"
            />
          </app-help-button>
        </div>
      </div>
    </div>

    <!-- Hint -->
    <!-- Use slot, or props' hint -->
    <div
      v-if="$slots.hint || hint"
      class="mt-1"
    >
      <template v-if="$slots.hint">
        <slot name="hint" />
      </template>

      <form-hint
        v-else-if="hint"
        :hint="hint"
      />
    </div>

    <!-- Errors -->
    <form-error-messages
      v-if="!hideErrors"
      :name="name"
      :class="errorClasses"
      :data-form-errors="true"
      :error-messages="errors.flat()"
    />
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  inject,
} from 'vue'
import { useField } from 'vee-validate'

import { translateAttributeName } from '@shared/helpers/attributes'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import FormControlTel from '@shared/components/form/controls/FormControlTel.vue'
import FormControlFile from '@shared/components/form/controls/FormControlFile.vue'
import FormControlTextarea from '@shared/components/form/controls/FormControlTextarea.vue'
import FormControlRadio from '@shared/components/form/controls/FormControlRadio.vue'
import FormControlSelect from '@shared/components/form/controls/FormControlSelect.vue'
import FormControlSortableList from '@shared/components/form/controls/FormControlSortableList.vue'
import FormControlHidden from '@shared/components/form/controls/FormControlHidden.vue'
import FormControlCheckbox from '@shared/components/form/controls/FormControlCheckbox.vue'
import FormControlSwitch from '@shared/components/form/controls/FormControlSwitch.vue'
import FormControlSlider from '@shared/components/form/controls/FormControlSlider.vue'
import FormControlPassword from '@shared/components/form/controls/FormControlPassword.vue'
import FormControlDatetime from '@shared/components/form/controls/FormControlDatetime.vue'
import FormControlDate from '@shared/components/form/controls/FormControlDate.vue'
import FormControlTime from '@shared/components/form/controls/FormControlTime.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import FormHint from '@shared/components/form/FormHint.vue'
import FormErrorMessages from '@shared/components/form/FormErrorMessages.vue'
import AppHelpButton from '@shared/components/ui/AppHelpButton.vue'

const props = defineProps({
  // form-control component's props
  formControlProps: {
    type: Object,
    default: () => ({}),
  },
  // form-label component's props
  formLabelProps: {
    type: Object,
    default: () => ({}),
  },
  // VeeValidate rules applied
  // to Field
  rules: {
    type: String,
    default: null,
  },
  // Determine label, input name,
  // VeeValidate name...
  name: {
    type: String,
    required: true,
  },
  // Determine form control
  // component used
  type: {
    type: String,
    default: 'text',
  },
  // Input placeholder
  // HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Input readonly HTML attribute
  readonly: {
    type: Boolean,
    default: false,
  },
  // Invalid or not
  invalid: {
    type: Boolean,
    default: false,
  },
  // Force the label
  label: {
    type: [String, Boolean],
    default: null,
  },
  // FormHint text
  hint: {
    type: String,
    default: null,
  },
  // Flex direction for label
  flexDirection: {
    type: String,
    default: 'col-reverse',
  },
  // Justify content
  justifyContent: {
    type: String,
    default: '',
  },
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Additional CSS classes
  // for error messages
  errorClasses: {
    type: String,
    default: null,
  },
  // Force the key used to generate
  // the error message,
  // if null the name will be used
  errorLabelI18nKey: {
    type: String,
    default: null,
  },
  // Hide errors in form
  hideErrors: {
    type: Boolean,
    default: false,
  },
  // Field is submittable or not
  submittable: {
    type: Boolean,
    default: true,
  },
  // Margins
  margins: {
    type: String,
    default: 'mb-3',
  },
  // Use label as placeholder
  labelAsPlaceholder: {
    type: Boolean,
    default: false,
  },
  // Styles of wrapper
  wrapperClasses: {
    type: String,
    default: 'flex items-center',
  },
})

const emits = defineEmits([
  'changed',
])

// Register field in VeeValidate form
const {
  errors,
  meta,
  handleChange,
  setTouched,
} = useField(
  props.name,
  props.rules,
  {
    label: props.errorLabelI18nKey,
    initialValue: props.initialValue,
  },
)

// Set this form field as submittable (used in form hook)
const formSubmittableFields = inject('formSubmittableFields', ref([]))
if (props.submittable && formSubmittableFields?.value) {
  formSubmittableFields.value.push(props.name)
}

// Function called when a form control emits "changed" event
function onChange(value) {
  handleChange(value) // Apply VeeValidate "handleChange" (triggers validation...)
  emits('changed', value)
}

// ---------- FORM CONTROL ----------

// Compute which component to use for the form control
const formControlComponent = computed(() => {
  let component

  switch (props.type) {
    case 'text':
    case 'email':
    case 'number':
    case 'url':
      component = FormControlText
      break
    case 'tel':
      component = FormControlTel
      break
    case 'file':
      component = FormControlFile
      break
    case 'textarea':
      component = FormControlTextarea
      break
    case 'radio':
      component = FormControlRadio
      break
    case 'select':
      component = FormControlSelect
      break
    case 'sortable-list':
      component = FormControlSortableList
      break
    case 'hidden':
      component = FormControlHidden
      break
    case 'checkbox':
      component = FormControlCheckbox
      break
    case 'switch':
      component = FormControlSwitch
      break
    case 'slider':
      component = FormControlSlider
      break
    case 'password':
      component = FormControlPassword
      break
    case 'datetime':
      component = FormControlDatetime
      break
    case 'date':
      component = FormControlDate
      break
    case 'time':
      component = FormControlTime
      break
    default:
      component = FormControlText
  }

  return component
})

// Compute props passed to the form-control component
const formControlPropsToUse = computed(() => {
  const finalPropsToUse = {}
  const propsAllowed = Object.keys(formControlComponent.value.props)
  const computedProps = {
    'initialValue': props.initialValue !== undefined ? props.initialValue : meta.initialValue,
    'type': typeToUse.value,
    'name': props.name,
    'required': required.value,
    'readonly': props.readonly,
    'placeholder': placeholderToUse.value,
    'focus': focus.value,
  }

  // Merge computed props and forced props
  const allProps = {
    ...computedProps,
    ...props.formControlProps, // Prioritize forced props
  }

  Object.entries(allProps).forEach(([key, value]) => {
    // Only use props register by the component
    if (propsAllowed.includes(key)) {
      finalPropsToUse[key] = value
    }
  })

  return finalPropsToUse
})

// Compute events passed to the form-control events
const formControlEvents = computed(() => {
  const finalEventsToUse = {}
  const eventsAllowed = formControlComponent.value.emits

  const baseEventsToUse = {
    changed: onChange,
    focused: handleFocus,
  }

  Object.entries(baseEventsToUse).forEach(([key, value]) => {
    // Only use events emitted by the component
    if (eventsAllowed.includes(key)) {
      finalEventsToUse[key] = value
    }
  })

  return finalEventsToUse
})

// ---------- FOCUS ----------

// If a control from the form group is focus
const focus = ref(false)

// Function called when a form control emits "focused" event
function handleFocus(state) {
  setTouched(true) // Set the field touched in VeeValidate
  focus.value = state // Set form group as focused
}

// ---------- RULES & ERRORS ----------

const required = computed(() => (
  props.rules?.split('|')?.includes('required')
))

const isInvalid = computed(() => (
  props.invalid || errors.value.length > 0
))

// ---------- TYPE ----------

// HTML type attribute used for form control
const typeToUse = computed(() => {
  if (props.type === 'credential') {
    if (formControlComponent.value === FormControlTel) {
      return 'tel'
    }

    return 'email'
  }

  return props.type
})

// ---------- LABEL & PLACEHOLDER ----------

// Compute the label to use
const labelToUse = computed(() => {
  // Do not use any label if it's set to false
  // or if the label is used as a placeholder
  if (props.label === false || props.labelAsPlaceholder) {
    return null
  }

  // Else, use the computed generated label
  return generatedLabel.value
})

// Generate the label
const generatedLabel = computed(() => {
  // Force the label, retrieved from props
  if (props.label) {
    return props.label
  }

  // Else, return the translated form group's name
  const { name } = props
  return translateAttributeName(name)
})

// Compute the placeholder to use
const placeholderToUse = computed(() => (
  props.labelAsPlaceholder
    ? generatedLabel.value // Use label as placeholder
    : props.placeholder // Use the placeholder prop
))

// Compute props passed to the form-label component
const formLabelPropsToUse = computed(() => {
  const computedProps = {
    'label': labelToUse.value,
    'for-html': props.name,
    'required': required.value,
    'invalid': isInvalid.value,
  }

  // Merge computed props and forced props
  return {
    ...computedProps,
    ...props.formLabelProps, // Prioritize forced props
  }
})

// ---------- STYLE ----------

// Compute "form-group" container classes
const classes = computed(() => {
  const classes = []

  // Style as focus, if not readonly
  if (focus.value && !props.readonly) {
    classes.push('form-group-focus')
  }

  // Hide form group
  if (props.type === 'hidden') {
    classes.push('hidden')
  }

  // Style form group as invalid
  if (isInvalid.value) {
    classes.push('form-group-invalid')
  }

  // Style as readonly
  if (props.readonly) {
    classes.push('opacity-50')
  }

  // Apply custom margins
  if (props.margins) {
    classes.push(props.margins)
  }

  return classes.join(' ')
})
</script>
