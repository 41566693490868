import {
  ref, computed, nextTick,
} from 'vue'
import {
  parsePhoneNumberFromString, getCountries, getCountryCallingCode, AsYouType,
} from 'libphonenumber-js'
import { isNil } from 'lodash'
import { useField } from 'vee-validate'

import useFormControl from '@shared/hooks/form/formControl'

export default function useFormControlTel(props, options = {}) {
  options.setInitialValue = setInitialValue
  const { innerValue, handleFocus, control } = useFormControl(props, options)

  // Country related to the international phone number
  // (e.g.: FR, CN, US...)
  const { value: countryCode } = useField(`${props.name}_country_code`)

  // Formatted number displayed to the user,
  // not always equal to innerValue
  const displayValue = ref(null)

  // Not a mere string, but an object create
  // with libphonenumber's parsePhoneNumber methods
  const phoneNumber = ref(null)

  const countries = computed(() => (
    getCountries().map((country) => (
      { value: country, label: `${country} +${getCountryCallingCode(country)}` }
    ))
  ))

  // Valid chars for phone numbers:
  //   digits: 0123456789,
  //   space,
  //   parenthesis: (),
  //   dash: -,
  //   plus: +,
  //   dot: .
  const PHONE_NUMBER_CHARS_REGEX = '\\d+\\s.()-'

  const containsNonPhoneNumberChars = (value) => {
    if (value?.match(new RegExp(`[^${PHONE_NUMBER_CHARS_REGEX}]`))) {
      return true
    }

    return false
  }

  function setInitialValue() {
    phoneNumber.value = parsePhoneNumberFromString(props.initialValue ? props.initialValue.toString() : '')
    adaptCountryCodeFromPhoneNumber()
    adaptDisplayValueFromPhoneNumber(props.initialValue)
    adaptInnerValueFromPhoneNumber(props.initialValue)
  }

  async function handlePhoneNumberInput(e) {
    const cursorPosition = e.target.selectionStart
    const newValue = e.target.value

    // Ignore tel formatting if it seems that the user try to input its email
    if (props.type === 'credential' && containsNonPhoneNumberChars(e.target.value)) {
      innerValue.value = e.target.value
    } else {
      phoneNumber.value = parsePhoneNumberFromString(newValue.toString(), countryCode.value)
      adaptCountryCodeFromPhoneNumber()
      adaptDisplayValueFromPhoneNumber(e.target.value)
      adaptInnerValueFromPhoneNumber(e.target.value)

      await nextTick()
      adaptPhoneNumberCursorPosition(newValue.length, cursorPosition)
    }
  }

  function adaptPhoneNumberCursorPosition(newValueLength, initialPosition) {
    // Avoid to lose cursor position after phone number formatting
    if (initialPosition < newValueLength) {
      options.refs.control.value.selectionEnd = initialPosition
    }
  }

  function adaptDisplayValueFromPhoneNumber(fallback) {
    if (!isNil(phoneNumber.value)) {
      // Format display value only if a related country is detected
      if (!isNil(phoneNumber.value.country)) {
        displayValue.value = new AsYouType(phoneNumber.value.country).input(phoneNumber.value.format('NATIONAL'))
      } else {
        // Set as is, without format
        displayValue.value = phoneNumber.value.number
      }
    } else {
      displayValue.value = fallback
    }
  }

  function adaptInnerValueFromPhoneNumber(fallback) {
    if (!isNil(phoneNumber.value)) {
      innerValue.value = phoneNumber.value.number
    } else {
      innerValue.value = fallback
    }
  }

  function handleCountryCodeChange(value) {
    countryCode.value = value
    adaptValuesFromCountryCode()
    options.refs.control.value.focus()
  }

  function adaptValuesFromCountryCode() {
    phoneNumber.value = parsePhoneNumberFromString(displayValue.value ? displayValue.value.toString() : '', countryCode.value)
    if (!isNil(phoneNumber.value)) {
      displayValue.value = new AsYouType(countryCode.value).input(phoneNumber.value.format('NATIONAL'))
      innerValue.value = phoneNumber.value.number
    }
  }

  function adaptCountryCodeFromPhoneNumber() {
    if (!isNil(phoneNumber.value?.country)) {
      countryCode.value = phoneNumber.value.country
    } else if (isNil(countryCode.value) && isNil(phoneNumber.value)) {
      countryCode.value = 'US'
    }
  }

  return {
    countryCode,
    displayValue,
    countries,
    innerValue,
    handlePhoneNumberInput,
    handleCountryCodeChange,
    handleFocus,
    control,
  }
}
