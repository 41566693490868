<template>
  <div
    :class="`shadow-inner-bottom shadow-gray-900/40 p-3 h-32 bg-cover bg-center ${resource.attributes.disabled ? 'bg-gray-500 grayscale' : 'bg-theme-500'} flex justify-center items-end duration-100`"
    :style="`background-image: url('${mainImageUrl}');`"
  >
    <p class="text-sm text-white font-bold break-words">
      {{ capitalize(getAttributeTranslation(resource.attributes.label)) }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { capitalize } from 'lodash'

import useLocale from '@shared/hooks/locale'
import useResource from '@shared/hooks/resources/resource'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const {
  getImageUrlFromResource,
} = useResource(props)

const { getAttributeTranslation } = useLocale()

const mainImageUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'sm')
))
</script>
