import i18n from '@shared/i18n/vue-i18n'
import { round, isNil } from 'lodash'

export const getDistanceFromCoordsInKm = (latitude1, longitude1, latitude2, longitude2) => {
  const coeff = 6371
  const dLat = deg2rad(latitude2 - latitude1)
  const dLon = deg2rad(longitude2 - longitude1)

  const a = (
    Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(latitude1)) * Math.cos(deg2rad(latitude2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  )

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return coeff * c
}

export const deg2rad = (deg) => (deg * (Math.PI / 180))

export const distanceToDisplay = (measurement_system, distance) => {
  let unit = i18n.global.t('common.units.km')

  if (measurement_system === 'imperial') {
    unit = i18n.global.t('common.units.mi')
  }

  return !isNil(distance)
    ? `${round(distance, 1)} ${unit}`
    : null
}
