<template>
  <label
    :for="forHtml"
    class="form-label font-medium"
  >
    <span>{{ labelToUse }}</span>
    <span v-if="label && required">*</span>
  </label>
</template>

<script setup>
import { capitalize, toUpper } from 'lodash'
import { computed } from 'vue'

const props = defineProps({
  // Content of span tag
  label: {
    type: String,
    default: null,
  },
  // "for" HTML attribute
  forHtml: {
    type: String,
    default: null,
  },
  // Display or not a
  // required notice
  required: {
    type: Boolean,
    default: false,
  },
  // Input type for parsing label...
  type: {
    type: String,
    default: null,
  },
})

// const labelRef = ref(null)

const labelToUse = computed(() => {
  switch (props.type) {
    case 'iban':
    case 'bic':
      return toUpper(props.label)
    default: return capitalize(props.label)
  }
})
</script>
