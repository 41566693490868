<template>
  <div class="relative">
    <input
      :id="name"
      v-model="innerValue"
      :class="`form-control w-full ${classes ? classes : ''} ${iconProps.name ? 'with-icon' : ''}`"
      :autocomplete="autocomplete"
      :type="typeToUse"
      :required="required"
      :placeholder="capitalize(placeholder)"
      :name="name"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
    >

    <!-- Visibility icon -->
    <app-icon
      library="coolicon"
      :name="typeToUse === 'password' ? 'show' : 'hide'"
      :classes="['absolute', 'right-4', 'top-1/2', '-translate-y-1/2', 'text-gray-500']"
      @click="toggleType"
    />

    <app-icon
      v-if="iconProps?.name"
      v-bind="iconProps"
      :classes="[...iconProps.classes || [], 'form-icon']"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { capitalize } from 'lodash'

import useFormControl from '@shared/hooks/form/formControl'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Placeholder HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // Autocomplete HTML attribute
  autocomplete: {
    type: String,
    default: null,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
  // AppIcon props
  iconProps: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })

// Input type HTML attribute
const typeToUse = ref('password')

// Toggle input type / password visibility
function toggleType() {
  if (typeToUse.value === 'password') {
    typeToUse.value = 'text'
  } else {
    typeToUse.value = 'password'
  }
}
</script>
