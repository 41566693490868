import {
  computed,
  ref,
  watch,
  nextTick,
  provide,
  toRefs,
} from 'vue'
import { useForm as useVVForm } from 'vee-validate'
import {
  get,
  set,
  unset,
  minBy,
} from 'lodash'
import { fromJS } from 'immutable'
import flatten from 'flat'

export default function useForm(props, options = {}) {
  // Retrieve initial values before filling the form fields
  const initialValues = options.initialValues
    ? fromJS(options.initialValues).toJS() // Use a safe deep copy
    : {}

  // Fill form field initial values based on "resource" prop
  fillFieldsFromResource(props?.resource)

  // Init VeeValidate form
  const form = useVVForm({
    initialValues,
  })

  // Provide form, to use it in children components
  provide(options.formName ? options.formName : 'form', ref(form))

  const {
    values, errors, setErrors, validate, resetForm,
  } = form

  // Submittable fields names array ; only those fields will be submitted.
  // Some fields exist only for display or calculation purpose,
  // and do not need to be submitted
  const submittableFields = ref([])
  // Provide submittable fields array, to be filled by children components
  provide('formSubmittableFields', submittableFields)

  // ---------- ERRORS ----------

  // Form's global errors : errors not bound to a specific and visible field
  const globalErrors = ref([])
  // Provide global errors, to display it in children components
  provide('formGlobalErrors', globalErrors)

  /**
   * Retrieve global errors object
   *
   * @returns {object}
   */
  function getGlobalErrors() {
    // Return errors not bound to an element to display it
    return Object.fromEntries(
      Object.entries(errors.value).filter((error) => {
        // Retrieve all error wrappers
        const errorWrappers = Array.from(document.querySelectorAll(`[name='${error[0]}'][data-form-errors='true']`))
        // Keep only displayed error wrappers (with positive client width)
        const displayedErrorWrappers = errorWrappers.filter((wrapper) => (wrapper.clientWidth > 0))
        // Check if there is no displayed wrapper
        return displayedErrorWrappers.length === 0
      }),
    )
  }

  /**
   * Refresh global errors and adapt VeeValidate errors to it
   */
  async function refreshGlobalErrors() {
    // Assign global errors values
    globalErrors.value = getGlobalErrors()

    // Await re-render so global errors appears and are ready to be scrolled to
    await nextTick()

    if (Object.keys(globalErrors.value).length > 0) {
      // Remove those individual errors from VeeValidate
      // as they have no visible form element related
      // they may not always be able to be resolved directly client-side before the server-side validation
      const updatedErrors = fromJS(errors.value).toJS()
      Object.keys(globalErrors.value).forEach((key) => {
        unset(updatedErrors, key)
      })

      setErrors(updatedErrors)
    }
  }

  // Form is invalid if there is at least 1 error
  const invalid = computed(() => (Object.keys(errors.value).length > 0))

  // Errors not provided by VeeValidate (e.g.: server-side errors)
  const { additionalErrors: propsAdditionalErrors } = toRefs(props)
  const refAdditionalErrors = ref({})

  // Watch additional errors and adapt form to it
  watch(refAdditionalErrors, (newErrors) => {
    adaptNewErrors(newErrors)
  })

  if (propsAdditionalErrors?.value) {
    watch(propsAdditionalErrors, (newErrors) => {
      adaptNewErrors(newErrors)
    })
  }

  async function adaptNewErrors(newErrors) {
    setErrors({ ...newErrors }) // Add those errors to VeeValidate
    await refreshGlobalErrors()
    focusFirstError() // Trigger the focus on the first error
  }

  // Compute errors keys
  const errorsKeys = computed(() => {
    const keys = Object.keys(errors.value)

    // Add globalErrors to keys, if present
    if (Object.keys(globalErrors.value).length > 0) {
      keys.push('globalErrors')
    }

    return keys
  })

  /**
   * Focus/Scroll to the first error
   */
  async function focusFirstError() {
    // Retrieve elements used to display each error,
    // by their name html attribute and data
    const formErrorsElements = errorsKeys.value
      .map((key) => Array.from(document.querySelectorAll(`[name='${key}'][data-form-errors='true']`)))
      .flat()

    // Filter elements, and only keep visible ones
    const visibleFormErrorsElements = formErrorsElements.filter((element) => (element?.clientWidth > 0))

    // Find the highest invalid element to scroll to
    const minimumOffsetTopInvalidElement = minBy(
      visibleFormErrorsElements,
      (el) => el?.offsetTop,
    )

    if (minimumOffsetTopInvalidElement) {
      await nextTick()
      // Scroll to the element and focus it
      await minimumOffsetTopInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      minimumOffsetTopInvalidElement.focus({ preventScroll: true })
    }
  }

  // ---------- SUBMIT ----------

  /**
   * Prepare form for submit
   */
  async function handleSubmit(emits = true) {
    // Trigger VeeValidate validation
    await validate()

    if (invalid.value) {
      await refreshGlobalErrors()
      await focusFirstError()
      return false
    }
    // Emits fields to submit
    // Make a deep copy to avoid side effects
    const fieldsToSubmit = fromJS(allFieldsToSubmit.value).toJS()

    if (emits && typeof options?.emits === 'function') {
      options.emits('submitted', fieldsToSubmit, { resetForm })
    }

    return fieldsToSubmit
  }

  /**
   * Get fields to submit
   *
   * @param {object} fields
   * @returns {object}
   */
  function getFieldsToSubmit(fields) {
    const fieldsToSubmit = {}

    // Only keep submittable fields
    submittableFields.value.forEach((fieldKey) => {
      const fieldValue = get(fields, fieldKey)
      set(fieldsToSubmit, fieldKey, fieldValue)
    })

    return fieldsToSubmit
  }

  const allFieldsToSubmit = computed(() => (
    getFieldsToSubmit(values)
  ))

  // ---------- FILL FIELDS ----------

  /**
   * Fill form fields from a JSON API resource
   *
   * @param {object} resource JSON API resource
   * @param {?string} keyToPrepend key to prepend each attribute, e.g.: 'media', will fill 'media.title', 'media.description' etc. attributes
   * @returns {string}
   */
  function fillFieldsFromResource(resource, keyToPrepend = null) {
    if (resource) {
      fillAttributesFieldsFromResource(resource, keyToPrepend)
      fillRelationshipsFieldsFromResource(resource, keyToPrepend)
    }
  }

  /**
   * Fill form fields from a JSON API resource's attributes
   *
   * @param {object} resource JSON API resource
   * @param {?string} keyToPrepend key to prepend each attribute, e.g.: 'media', will fill 'media.title', 'media.description' etc. attributes
   * @returns {string}
   */
  function fillAttributesFieldsFromResource(resource, keyToPrepend = null) {
    if (resource?.attributes) {
      // Retrieve each attribute's name
      const attributesNames = Object
        .keys(flatten(resource.attributes)) // Add each attribute name, even nested ones
        .concat(['id']) // Add "id" manually, because it's not in resource's attributes

      // Process each attribute
      attributesNames.forEach((attributeName) => {
        // Compute field key
        let fieldKey
        if (keyToPrepend) {
          // Prepend key to attribute name
          fieldKey = `${keyToPrepend}.${attributeName}`
        } else {
          fieldKey = attributeName
        }

        // Compute field value
        let fieldValue
        if (attributeName === 'id') {
          // "id" is not retrieved from the attributes
          fieldValue = resource.id
        } else {
          // Retrieve attribute's value
          fieldValue = get(resource.attributes, attributeName)
        }

        // Fill form fields with attribute value
        set(initialValues, fieldKey, fieldValue)
      })
    }
  }

  /**
   * Fill form fields from a JSON API resource's relationships
   *
   * @param {object} resource JSON API resource
   * @param {?string} keyToPrepend key to prepend each attribute, e.g.: 'media', will fill 'media.title', 'media.description' etc. attributes
   * @returns {string}
   */
  function fillRelationshipsFieldsFromResource(resource, keyToPrepend = null) {
    if (resource?.relationships) {
      Object.entries(resource.relationships).forEach((relationship) => {
        // Retrieve relation's name and data
        const relationName = relationship[0]
        const relationData = relationship[1]

        // If relation is an array, it's a ToMany relation with several ids,
        // else it's a ToOne with a single id
        const isToManyRelation = Array.isArray(relationData)

        // Compute relation key
        let relationFieldsKey
        if (keyToPrepend) {
          relationFieldsKey = `${keyToPrepend}.${relationName}`
        } else {
          relationFieldsKey = relationName
        }

        let idFieldKey
        let idFieldValue

        // Set id or ids fields depending on the relationtype
        if (isToManyRelation) {
          // Compute ID field's value and key
          idFieldKey = `${relationFieldsKey}_ids`
          idFieldValue = relationData.map((relation) => relation.id)

          // Fill relation fields too
          relationData.forEach((relation, relationIndex) => {
            fillFieldsFromResource(relation, `${relationFieldsKey}.${relationIndex}`)
          })
        } else if (relationData) {
          // Compute ID field's key
          if (resource.metadata?.relationships?.[relationName]?.foreignKeyName) {
            idFieldKey = resource.metadata.relationships[relationName].foreignKeyName
          } else {
            idFieldKey = `${relationFieldsKey}_id`
          }

          // Compute ID field's value
          idFieldValue = relationData.id

          // Fill relation fields too
          fillFieldsFromResource(relationData, relationFieldsKey)
        }

        // Fill form fields with relation id(s)
        set(initialValues, idFieldKey, idFieldValue)
      })
    }
  }

  return {
    handleSubmit,
    invalid,
    values,
    form,
    allFieldsToSubmit,
    refAdditionalErrors,
  }
}
