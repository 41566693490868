<template>
  <Multiselect
    ref="multiselect"
    v-model="innerValue"
    :mode="mode"
    :placeholder="upperFirst(placeholder)"
    :name="name"
    :options="options"
    :searchable="searchable"
    :filter-results="filterable"
    :strict="false"
    :required="required"
    :can-clear="clearable"
    :can-deselect="deselectable"
    :close-on-select="closeOnSelect"
    :autocomplete="`new-${name}`"
    :no-results-text="t('form.select.no_results')"
    :no-options-text="t('form.select.no_options')"
    input-type="search"
    :min-chars="minChars"
    :resolve-on-load="searchOnLoad"
    :delay="optionsAsync ? 400 : -1"
    :open-direction="openDirection"
    @open="handleFocus(true)"
    @close="handleFocus(false)"
  >
    <template
      v-if="Object.keys($slots).includes('control-vue-multiselect-option')"
      #option="{ option }"
    >
      <slot
        name="control-vue-multiselect-option"
        v-bind="option"
      />
    </template>

    <template
      v-if="Object.keys($slots).includes('control-vue-multiselect-singlelabel')"
      #singlelabel="{ value }"
    >
      <div class="multiselect-single-label">
        <slot
          name="control-vue-multiselect-singlelabel"
          v-bind="value"
        />
      </div>
    </template>

    <template
      v-if="searchable"
      #beforelist
    >
      <p class="pt-2 text-center text-sm text-gray-500">
        {{ searchHeaderTextToUse }}
      </p>
    </template>
  </Multiselect>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { upperFirst } from 'lodash'

import useFormControl from '@shared/hooks/form/formControl'
import Multiselect from '@vueform/multiselect'

const props = defineProps({
  // Value used to fill inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Placeholder HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Single, multiple or tags
  mode: {
    type: String,
    default: 'single',
  },
  // Select allow taggable options or not
  taggable: {
    type: Boolean,
    default: false,
  },
  // Select allow search or not
  searchable: {
    type: Boolean,
    default: true,
  },
  // Select is filterable by search text or not
  filterable: {
    type: Boolean,
    default: true,
  },
  // Close option list on select
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  // Select is clearable or not
  clearable: {
    type: Boolean,
    default: false,
  },
  // Single option is deselectable or not
  deselectable: {
    type: Boolean,
    default: true,
  },
  // Minimum search characters for async options
  minChars: {
    type: Number,
    default: 0,
  },
  // Search async options at initialisation
  searchOnLoad: {
    type: Boolean,
    default: true,
  },
  // Options available (for select, radio...)
  options: {
    type: [Array, Object, Function],
    default: () => ([]),
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
  // Text displayed in options header
  searchHeaderText: {
    type: String,
    default: null,
  },
  // Open direction
  openDirection: {
    type: String,
    default: 'bottom',
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const { t } = useI18n()
const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })

const searchHeaderTextToUse = computed(() => (
  props.searchHeaderText ?? t('form.select.type_to_search')
))

// Options are async if it's a function
const optionsAsync = computed(() => (
  typeof props.options === 'function'
))
</script>
