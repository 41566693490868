<template>
  <div
    :class="[width, position, top, right].join(' ')"
    class="bg-white rounded-full flex justify-center items-center min-w-6 min-h-6 h-6"
  >
    <font-awesome-icon
      class="text-theme-400"
      icon="heart"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // Icon width
  width: {
    type: String,
    default: 'w-6',
  },
  // Display mode
  mode: {
    type: String,
    default: 'card',
    validator(value) {
      return [
        'card',
        'show',
      ].includes(value)
    },
  },
})

// ---------- STYLE ----------

const isCardMode = computed(() => (
  props.mode === 'card'
))

const position = computed(() => (
  isCardMode.value ? 'absolute' : 'relative'
))

const top = computed(() => (
  isCardMode.value ? 'top-2' : 'top-0'
))

const right = computed(() => (
  isCardMode.value ? 'right-2' : 'right-0'
))
</script>
