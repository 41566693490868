<template>
  <div>
    <input
      :id="name"
      ref="control"
      class="hidden"
      type="file"
      :name="name"
      :required="required"
      :accept="mimeTypesAccepted"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @change="handleFileUpload"
    >

    <app-button
      feature="attach"
      class="text-sm"
      @click="control.click()"
    />

    <span class="ml-4">
      {{ innerValue?.name }}
    </span>
  </div>

  <div
    v-if="s3Private && initialValue"
    class="flex content-center mb-4 text-sm"
  >
    {{ t('form.file.current_file', { name: initialValue }) }}

    <app-button
      emphasis="low"
      hide-text
      :hide-icon="false"
      :icon-props="{ library: 'coolicon', name: 'show' }"
      :label="t('common.actions.file_preview')"
      class="ml-2"
      @click="handleFilePreview(initialValue)"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormControl from '@shared/hooks/form/formControl'
import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Files' mime types accepted,
  // separated by comma
  mimeTypesAccepted: {
    type: String,
    default: null,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
  // Use s3 private disk?
  s3Private: {
    type: Boolean,
    default: false,
  },
  // Function called on file preview
  filePreviewCallback: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'focused',
  'changed',
  'file_preview',
])

const control = ref(null)
const uploadedFile = ref(null)

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })

function handleFileUpload(e) {
  // Check if a file is present,
  // useful when upload is cancel
  const targetFile = e.target.files?.[0]

  if (targetFile) {
    innerValue.value = targetFile
    uploadedFile.value = targetFile
  }
}

// ---------- FILE PREVIEW ----------

const { t } = useI18n()

function handleFilePreview(fileName) {
  emits('file_preview')
  props.filePreviewCallback(fileName)
}
</script>
