<template>
  <div class="relative">
    <div class="absolute right-2 top-2">
      <excursion-icon
        :resource="resource"
      />
    </div>

    <div class="flex flex-col whitespace-nowrap">
      <!-- Image -->
      <router-link
        :to="resource.id ? { name: 'show-excursions', params: { id: resource.id } } : {}"
        :class="`h-32 ${imageStyle} bg-center`"
        :style="`background-image: url(${mainImageUrl});`"
      />

      <!-- Details -->
      <div class="p-3">
        <router-link
          :to="resource.id ? { name: 'show-excursions', params: { id: resource.id } } : {}"
          class="block truncate text-base font-bold text-left"
        >
          {{ l10nTitle }}
        </router-link>

        <excursion-actions
          v-if="!hideActions"
          :resource="resource"
          class="justify-end"
          @destroyed="destroyCallback"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useExcursion from '@shared/hooks/resources/excursion'
import ExcursionActions from '@app/components/resources/excursion/ExcursionActions.vue'
import ExcursionIcon from '@shared/components/resources/excursion/ExcursionIcon.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // hide actions
  hideActions: {
    type: Boolean,
    default: false,
  },
})

const {
  getMainImageUrl,
  l10nTitle,
  isPlaceholderImage,
} = useExcursion(props)

// ---------- IMAGE ----------

const mainImageUrl = computed(() => (
  getMainImageUrl('sm')
))

const imageStyle = computed(() => (
  isPlaceholderImage(mainImageUrl.value)
    ? 'bg-contain bg-no-repeat'
    : 'bg-cover'
))
</script>
