import { useI18n } from 'vue-i18n'
import { isNil } from 'lodash'

export default function useResource() {
  const { t } = useI18n()

  // Point of interest
  const POI_TYPE = 'pointsofinterest'
  const POI_CLASS_NAME = 'App\\Models\\PointOfInterest'
  const POI_SHOW_ROUTE = 'show-points-of-interest'
  const POI_ICON = {
    library: 'coolicon',
    name: 'location_outline',
  }

  // Excursion
  const EXCURSION_TYPE = 'excursions'
  const EXCURSION_CLASS_NAME = 'App\\Models\\Excursion'
  const EXCURSION_SHOW_ROUTE = 'show-excursions'
  const EXCURSION_ICON = {
    library: 'coolicon',
    name: 'map',
  }

  // User
  const USER_TYPE = 'users'
  const USER_CLASS_NAME = 'App\\Models\\User'
  const USER_SHOW_ROUTE = 'show-users'
  const USER_ICON = {
    library: 'coolicon',
    name: 'user',
  }

  // Traveller folder
  const TRAVELLER_FOLDER_TYPE = 'travellerfolders'
  const TRAVELLER_FOLDER_CLASS_NAME = 'App\\Models\\TravellerFolder'
  const TRAVELLER_FOLDER_SHOW_ROUTE = 'show-traveller-folders'
  const TRAVELLER_FOLDER_ICON = 'folder'

  // Payment
  const PAYMENT_TYPE = 'payments'
  const PAYMENT_CLASS_NAME = 'App\\Models\\Payment'
  const PAYMENT_SHOW_ROUTE = 'finances'
  const PAYMENT_ICON = 'money-bill'

  // Support Request
  const SUPPORT_REQUEST_CLASS_NAME = 'App\\Models\\SupportRequest'
  const SUPPORT_REQUEST_SHOW_ROUTE = 'show-support-requests'

  // ---------- ICON ----------

  // Return font awesome icon name
  function getIconFromClassName(className) {
    switch (className) {
      case POI_CLASS_NAME:
        return POI_ICON
      case EXCURSION_CLASS_NAME:
        return EXCURSION_ICON
      case USER_CLASS_NAME:
        return USER_ICON
      case TRAVELLER_FOLDER_CLASS_NAME:
        return TRAVELLER_FOLDER_ICON
      case PAYMENT_CLASS_NAME:
        return PAYMENT_ICON
      default:
        return null
    }
  }

  // Return font awesome icon name
  function getIconFromResourceType(resourceType) {
    switch (resourceType) {
      case POI_TYPE:
        return POI_ICON
      case EXCURSION_TYPE:
        return EXCURSION_ICON
      case USER_TYPE:
        return USER_ICON
      case TRAVELLER_FOLDER_TYPE:
        return TRAVELLER_FOLDER_ICON
      case PAYMENT_TYPE:
        return PAYMENT_ICON
      default:
        return null
    }
  }

  // ---------- ROUTE ----------

  // Return vue-router "to" param
  function getRouteFromClassName(className, id) {
    if (!id) {
      return {}
    }

    switch (className) {
      case POI_CLASS_NAME:
        return {
          name: POI_SHOW_ROUTE,
          params: { id },
        }
      case EXCURSION_CLASS_NAME:
        return {
          name: EXCURSION_SHOW_ROUTE,
          params: { id },
        }
      case USER_CLASS_NAME:
        return {
          name: USER_SHOW_ROUTE,
          params: { id },
        }
      case TRAVELLER_FOLDER_CLASS_NAME:
        return {
          name: TRAVELLER_FOLDER_SHOW_ROUTE,
          params: { id },
        }
      case PAYMENT_CLASS_NAME:
        return {
          name: PAYMENT_SHOW_ROUTE,
          params: {},
        }
      case SUPPORT_REQUEST_CLASS_NAME:
        return {
          name: SUPPORT_REQUEST_SHOW_ROUTE,
          params: { id },
        }
      default:
        return {}
    }
  }

  // Return vue-router "to" param
  function getRouteFromResourceType(resourceType, id) {
    if (!id) {
      return {}
    }

    switch (resourceType) {
      case POI_TYPE:
        return {
          name: POI_SHOW_ROUTE,
          params: { id },
        }
      case EXCURSION_TYPE:
        return {
          name: EXCURSION_SHOW_ROUTE,
          params: { id },
        }
      case USER_TYPE:
        return {
          name: USER_SHOW_ROUTE,
          params: { id },
        }
      case TRAVELLER_FOLDER_TYPE:
        return {
          name: TRAVELLER_FOLDER_SHOW_ROUTE,
          params: { id },
        }
      case PAYMENT_TYPE:
        return {
          name: PAYMENT_SHOW_ROUTE,
          params: {},
        }
      default:
        return {}
    }
  }

  // ---------- NAME ----------

  function getNameFromResourceType(resourceType, nb = 1) {
    switch (resourceType) {
      case POI_TYPE:
        return t('models.point_of_interest', nb)
      case EXCURSION_TYPE:
        return t('models.excursion', nb)
      case USER_TYPE:
        return t('models.user', nb)
      case TRAVELLER_FOLDER_TYPE:
        return t('models.traveller_folder', nb)
      case PAYMENT_TYPE:
        return t('models.payment', nb)
      default:
        return null
    }
  }

  // ---------- IMAGE ----------

  function getImageFromResource(resource) {
    return resource?.relationships?.image
  }

  function getImageUrlFromResource(resource, version = 'sm') {
    const image = getImageFromResource(resource)

    if (image) {
      // Return image url
      return image?.attributes?.urls?.[version]
    }

    // Return placeholder url
    return `/images/shared/placeholders/${resource?.type}.png`
  }

  // Check if a resource already exists server-side
  function resourceExists(resource) {
    return (
      !isNil(resource?.id)
    )
  }

  return {
    getIconFromClassName,
    getIconFromResourceType,
    getRouteFromClassName,
    getRouteFromResourceType,
    getNameFromResourceType,
    getImageFromResource,
    getImageUrlFromResource,
    resourceExists,
    POI_TYPE,
    POI_CLASS_NAME,
    EXCURSION_TYPE,
    EXCURSION_CLASS_NAME,
    USER_TYPE,
    USER_CLASS_NAME,
  }
}
