import {
  formatDateShort,
  formatDateAbbrev,
  formatDateFull,
  addDaysToDate,
  diffInDays,
  formatTimeSimple,
  formatTimeWithSeconds,
  formatDateWithTime,
  currentDate,
  currentIsoDateTime,
} from '@shared/helpers/datetime'

export default function useDatetime() {
  return {
    // Date functions
    formatDateShort,
    formatDateAbbrev,
    formatDateFull,
    addDaysToDate,
    diffInDays,

    // Time functions
    formatTimeSimple,
    formatTimeWithSeconds,

    // Date with time
    formatDateWithTime,

    // Current date / time
    currentDate,
    currentIsoDateTime,
  }
}
