<template>
  <div class="flex items-center w-full">
    <p
      class="truncate w-full"
    >
      {{ text }}
    </p>

    <app-icon
      library="local"
      name="copy"
      :classes="['cursor-pointer', leftMargin, 'text-theme-500']"
      :size="size"
      @click="copy"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import useClipboard from 'vue-clipboard3'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Text to display
  text: {
    type: String,
    default: null,
  },
  // Size
  size: {
    type: String,
    default: null,
  },
  // Margin left
  leftMargin: {
    type: String,
    default: 'ml-1',
  },
})

const { t } = useI18n()
const store = useStore()

const { toClipboard } = useClipboard()

const copy = async () => {
  try {
    await toClipboard(props.text.trim())
    store.commit(
      'flashes/ADD_FLASH',
      {
        message: t('common.messages.clipboard.success'),
        type: 'success',
      },
    )
  } catch (e) {
    store.commit(
      'flashes/ADD_FLASH',
      {
        message: t('common.messages.clipboard.error'),
        type: 'danger',
      },
    )
  }
}
</script>
