<template>
  <app-overlay
    :show="show"
    @clicked="handleClose"
  >
    <app-modal
      padding="pt-4 px-4"
      @closed="handleClose"
    >
      <vue-qrcode
        :value="url"
        tag="img"
        class="mb-4 mx-auto"
        :size="250"
      />

      <p class="font-bold">
        {{ t('users.profile.qr_code') }}
      </p>

      <app-copy-clipboard
        class="rounded w-full bg-slate-200 px-3 py-2 mt-4 justify-between"
        :text="url"
        :size="16"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import VueQrcode from '@chenfengyuan/vue-qrcode'

import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppCopyClipboard from '@shared/components/ui/AppCopyClipboard.vue'

defineProps({
  // Show modal
  show: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'closed',
])

const { t } = useI18n()
const store = useStore()
const router = useRouter()

// ---------- QRCODE URL ----------

const domain = window.location.origin

const route = router.resolve({
  name: 'show-users',
  params: { id: store.state.auth?.user?.id },
})

const url = `${domain}${route.href}`

function handleClose() {
  emits('closed')
}
</script>
