import {
  onMounted,
  ref,
  watch,
  nextTick,
  computed,
} from 'vue'
import { useField } from 'vee-validate'

export default function useFormControl(props, options = {}) {
  const innerValue = ref(null)
  const initialValueSet = ref(false) // has the initial value been set
  const control = ref(null) // ref to html form control (input, textarea etc.)

  const { value: fieldValue } = useField(props.name)

  // refresh v-model's value when field value has been updated programmatically
  // TODO: Comment, Try staging to check for regression.
  // watch(fieldValue, (newValue) => {
  //   if (newValue !== innerValue.value) {
  //     innerValue.value = newValue
  //   }
  // })

  // Set form control's initial value
  function setInitialValue() {
    if (options.setInitialValue) {
      // Either by a callback option
      options.setInitialValue()
    } else if (props.initialValue !== undefined) {
      // Or by a prop, if provided
      innerValue.value = props.initialValue
      fieldValue.value = props.initialValue
    }
  }

  // Emits "focused" state
  function handleFocus(state) {
    options.emits('focused', state)
  }

  function clearValue() {
    innerValue.value = null
  }

  watch(innerValue, (newInnerValue) => {
    // Emit changed event when innerValue changes
    // and only if initial value has already been set
    if (initialValueSet.value) {
      options.emits('changed', newInnerValue)
    }
  }, { deep: true })

  // Compute value's length
  const length = computed(() => (
    innerValue.value?.length
  ))

  onMounted(async () => {
    setInitialValue()
    await nextTick()
    // Wait next tick before setting "initialValueSet" to true,
    // otherwise "changed" event will be triggered too soon,
    // before value's reflected in template
    initialValueSet.value = true
  })

  return {
    innerValue,
    handleFocus,
    control,
    length,
    clearValue,
  }
}
