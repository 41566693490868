<template>
  <div class="relative">
    <input
      :id="name"
      v-model="innerValue"
      :class="`form-control w-full ${classes ? classes : ''} ${iconProps.name ? 'with-icon' : ''}`"
      :style="styles"
      :autocomplete="autocomplete"
      :type="type"
      :required="required"
      :placeholder="upperFirst(placeholder)"
      :name="name"
      :min="min"
      :max="max"
      :readonly="readonly"
      :inputmode="inputmode"
      @input="handleInput"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @keydown.enter="enterEventHandler"
    >

    <app-icon
      v-if="iconProps?.name"
      v-bind="iconProps"
      :classes="[...iconProps.classes || [], 'form-icon']"
    />
  </div>
</template>

<script setup>
import { upperFirst } from 'lodash'

import useFormControl from '@shared/hooks/form/formControl'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Input type HTML attribute
  // (e.g.: text, email, password...)
  type: {
    type: String,
    default: 'text',
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Placeholder HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Readonly HTML attribute
  readonly: {
    type: Boolean,
    default: false,
  },
  // Input mode HTML attribute
  inputmode: {
    type: String,
    default: null,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // Min HTML attribute
  min: {
    type: Number,
    default: null,
  },
  // Max HTML attribute
  max: {
    type: Number,
    default: null,
  },
  // Autocomplete HTML attribute
  autocomplete: {
    type: String,
    default: null,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
  // CSS styles
  styles: {
    type: String,
    default: null,
  },
  // AppIcon props
  iconProps: {
    type: Object,
    default: () => ({}),
  },
  // "enter" event handler,
  enterEventHandler: {
    type: Function,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })

/**
 * Format the text according to the input type.
 */
function handleInput() {
  if (props.type === 'iban') {
    innerValue.value = innerValue.value
      .toUpperCase()
      .replace(/[^a-zA-Z0-9]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim()
  }
}
</script>
