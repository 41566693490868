import { computed } from 'vue'

import useLocale from '@shared/hooks/locale'
import useResource from '@shared/hooks/resources/resource'

export default function useExcursion(props) {
  const { getAttributeTranslation } = useLocale()
  const {
    getImageFromResource,
    getImageUrlFromResource,
    getIconFromResourceType,
    EXCURSION_TYPE,
  } = useResource()

  const placeholderImage = '/images/shared/placeholders/excursions.png'

  const mainImage = computed(() => (
    getImageFromResource(props.resource)
  ))

  function getMainImageUrl(version = 'sm') {
    return getImageUrlFromResource(props.resource, version)
  }

  function isPlaceholderImage(url) {
    return url === placeholderImage
  }

  const l10nTitle = computed(() => (
    getAttributeTranslation(props.resource.attributes?.title)
  ))

  const iconName = getIconFromResourceType(EXCURSION_TYPE)

  const pointsOfInterest = computed(() => (
    props.resource?.relationships?.points_of_interest ?? []
  ))

  // ---------- DIRECTION MAP ----------

  const addresses = computed(() => (
    pointsOfInterest.value.map((pointOfInterest) => (
      pointOfInterest.relationships.address
    ))
  ))

  // Addresses used for direction map
  const directionAddresses = computed(() => (
    addresses.value.filter((address) => (
      !address.attributes.is_geocoding // Do not provide direction for address currently geocoding
      // Coordinates must be present
        && address.attributes.latitude
        && address.attributes.longitude
    ))
  ))

  // Coordinates used for direction map
  const directionCoordinates = computed(() => (
    // Only keep addresses' coordinates
    directionAddresses.value.map((address) => (
      {
        latitude: address.attributes.latitude,
        longitude: address.attributes.longitude,
      }
    ))
  ))

  return {
    l10nTitle,
    mainImage,
    directionCoordinates,
    pointsOfInterest,

    // Methods
    iconName,
    getMainImageUrl,
    isPlaceholderImage,
  }
}
