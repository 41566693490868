import axios from 'axios'

export const apiAxios = axios.create({
  baseURL: 'https://api.mapbox.com',
  params: {
    access_token: import.meta.env.VITE_MAPBOX_API_KEY,
  },
})

export const geocoding = (search, payload) => apiAxios.get(
  `/geocoding/v5/mapbox.places/${search}.json`,
  {
    params: payload,
  },
)
