<template>
  <div class="flex items-center">
    <router-link
      v-if="logoUrl"
      :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
    >
      <img
        :src="logoUrl"
        class="w-16"
      >
    </router-link>

    <div class="w-9/12 ml-1">
      <router-link
        class="font-bold"
        :to="{ name: 'show-points-of-interest', params: { id: resource.id } }"
      >
        {{ l10nTitle }}
      </router-link>

      <app-external-link
        v-if="resource.attributes.contact?.phone"
        :link="resource.attributes.contact.phone"
        class="mt-2 text-sm"
        type="phone"
      />

      <app-external-link
        v-if="resource.attributes.contact?.email"
        :link="resource.attributes.contact.email"
        class="text-sm"
        type="email"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppExternalLink from '@shared/components/ui/AppExternalLink.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const {
  l10nTitle,
} = usePointOfInterest(props)

const logoUrl = computed(() => (
  props.resource.relationships.logo?.attributes?.urls?.xs
))

</script>
