<template>
  <app-alert
    v-if="display"
    name="globalErrors"
    :data-form-errors="true"
    design="danger"
  >
    <ul>
      <li
        v-for="(error, index) in globalErrors"
        :key="index"
      >
        {{ error }}
      </li>
    </ul>
  </app-alert>
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import AppAlert from '@shared/components/ui/AppAlert.vue'

const globalErrors = computed(() => (
  inject('formGlobalErrors', ref([]))?.value
))

const display = computed(() => (
  globalErrors.value ? Object.keys(globalErrors.value).length > 0 : false
))
</script>
