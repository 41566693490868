<template>
  <div class="relative">
    <!-- Remove button -->
    <app-button
      feature="close"
      :hide-icon="false"
      :hide-text="true"
      width="4"
      height="4"
      rounded="rounded-1/2"
      padding="p-0"
      :gradient="false"
      :icon-props="{ size: 'text-2xs' }"
      class="absolute -top-1 -right-1 flex items-center justify-center"
      @click="clearValue"
    />

    <input
      :id="name"
      v-model="innerValue"
      :class="`form-control w-full ${classes ? classes : ''}`"
      :autocomplete="autocomplete"
      type="date"
      :required="required"
      :placeholder="capitalize(placeholder)"
      :name="name"
      :readonly="readonly"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
    >
  </div>
</template>

<script setup>
import { capitalize } from 'lodash'

import useFormControl from '@shared/hooks/form/formControl'
import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Placeholder HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Readonly HTML attribute
  readonly: {
    type: Boolean,
    default: false,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // Autocomplete HTML attribute
  autocomplete: {
    type: String,
    default: null,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
  clearValue,
} = useFormControl(props, { emits })
</script>
