import { computed } from 'vue'
import { useStore } from 'vuex'
import { languagesAll, countries } from 'countries-list'
import { compact, capitalize } from 'lodash'

import i18n from '@shared/i18n/vue-i18n'
import { updateCurrentUser } from '@shared/http/api'
import { getAttributeTranslation } from '@shared/helpers/attributes'
import { translationExists } from '@shared/helpers/translations'

export default function useLocale() {
  const store = useStore()

  function handleChange(value) {
    return new Promise((resolve) => {
      // Change SPA locale
      i18n.global.locale.value = value

      if (store?.state?.auth?.authenticated) {
        // Send new locale to API
        const params = {
          data: {
            attributes: {
              locale: value,
            },
          },
        }

        updateCurrentUser(params).finally(() => {
          // Save user's locale client side
          const updatedUser = store.state.auth.user
          updatedUser.attributes.locale = value

          store.commit('auth/SET_USER', updatedUser)
          store.commit('router/REFRESH_MAIN_KEY')
          resolve()
        })
      } else {
        localStorage.setItem('locale', value)
        resolve()
      }
    })
  }

  // Available locales for static translations
  const staticLocales = computed(() => (
    store.state.locales.statics.map((l) => l.attributes.iso_code)
  ))
  const staticLocalesOptions = computed(() => localeOptions(
    staticLocales.value,
    false,
  ))
  const staticLocalesOptionsTranslated = computed(() => localeOptions(
    staticLocales.value,
    true,
  ))

  // Available locales for dynamic translations
  const dynamicLocales = computed(() => (
    store.state.locales.dynamics.map((l) => l.attributes.iso_code)
  ))
  const dynamicLocalesOptions = computed(() => localeOptions(
    dynamicLocales.value,
    false,
  ))

  // Available locales for complete languages list
  const allLocales = computed(() => Object.keys(languagesAll))
  const allLocalesOptions = computed(() => localeOptions(allLocales.value))

  /**
   * Locales options, with iso code as value, and translation as label
   *
   * @param {array} locales array of iso codes
   * @param {boolean} self translate label in each locale or not
   * @returns {array}
   */
  function localeOptions(locales, self = false) {
    const options = locales.map((locale) => {
      const translationLocale = self ? locale : i18n.global.locale // Translate in its self locale, or in global locale
      const translationKey = `locales.${locale}`

      // If translated locale does not exist, set option as null
      return translationExists(translationKey, translationLocale)
        ? {
          value: locale,
          label: capitalize(
            i18n.global.t(
              `locales.${locale}`,
              {},
              { locale: translationLocale.value },
            ),
          ),
        }
        : null
    })

    // Remove null options
    return compact(options)
  }

  /**
   * Languages options for a specific country
   *
   * @param {string | null} countryCode Set this country's languages as first options
   * @param {boolean} strict Only keep the country languages as options
   * @returns {array}
   */
  function localeOptionsByCountry(countryCode, strict = false) {
    let options = allLocalesOptions.value

    if (countryCode) {
      const countryLanguages = countries[countryCode]?.languages

      if (countryLanguages) {
        if (strict) {
          // Filter option to specific country's languages only
          options = options.filter((option) => (
            countryLanguages.includes(option.value)
          ))
        } else {
          // Sort locales with the country's languages as first
          options.sort((a, b) => {
            if (
              countryLanguages.includes(a.value)
              && countryLanguages.includes(b.value)
            ) {
              return 0
            }

            if (countryLanguages.includes(a.value)) {
              return -1
            }

            return 1
          })
        }
      }
    }

    return options
  }

  // 2 Alpha locale, e.g.: "fr" and not "fr-FR"
  const shortLocale = computed(() => (
    i18n.global.locale.value?.substring(0, 2)
  ))

  return {
    handleChange,
    staticLocalesOptions,
    staticLocalesOptionsTranslated,
    dynamicLocales,
    dynamicLocalesOptions,
    allLocalesOptions,
    localeOptionsByCountry,
    shortLocale,
    getAttributeTranslation,
    translationExists,
  }
}
