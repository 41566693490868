<template>
  <div
    :class="containerStyle"
  >
    <input
      :id="name"
      v-model="innerValue"
      :name="name"
      type="checkbox"
      :class="switchStyle"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
    >

    <label
      :for="name"
      :class="labelStyle"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { twMerge } from 'tailwind-merge'

import useFormControl from '@shared/hooks/form/formControl'

const props = defineProps({
  // Value used to fill inner
  // value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Label name
  label: {
    type: String,
    default: null,
  },
  // Container CSS classes
  containerClasses: {
    type: String,
    default: null,
  },
  // Switch CSS classes
  switchClasses: {
    type: String,
    default: null,
  },
  // Label CSS classes
  labelClasses: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })

// ---------- CONTAINER STYLE ----------

const defaultContainerStyle = computed(() => ([
  'relative',
  'inline-block',
  'w-14',
  'mr-2',
  'align-middle',
  'select-none',
  'transition',
  'duration-200',
  'ease-in',
]))

const containerStyle = computed(() => (
  twMerge([
    ...defaultContainerStyle.value,
    props.containerClasses,
  ]
    .join(' '))
))

// ---------- SWITCH STYLE ----------

const defaultSwitchStyle = computed(() => ([
  'toggle-checkbox',
  'absolute',
  'block',
  'w-8',
  'h-8',
  'rounded-full',
  'bg-white',
  'border-3',
  'border-gray-500',
  'appearance-none',
  'cursor-pointer',
]))

const switchStyle = computed(() => (
  twMerge([
    ...defaultSwitchStyle.value,
    props.switchClasses,
  ]
    .join(' '))
))

// ---------- LABEL STYLE ----------

const defaultLabelStyle = computed(() => ([
  'toggle-label',
  'block',
  'overflow-hidden',
  'h-8',
  'rounded-full',
  'bg-gray-500',
  'cursor-pointer',
]))

const labelStyle = computed(() => (
  twMerge([
    ...defaultLabelStyle.value,
    props.labelClasses,
  ]
    .join(' '))
))
</script>

<style scoped>
.toggle-checkbox:checked {
  @apply right-0 border-theme-500;
}

.toggle-checkbox:checked + .toggle-label {
  @apply bg-theme-500
}
</style>
