<template>
  <app-card-item
    class="flex flex-col w-full"
  >
    <div
      :class="`${padding} ${fontSize} font-bold flex justify-between items-center`"
      @click="toggleResourceDisplayed"
    >
      <div class="flex w-full justify-between">
        <p>
          {{ props.title }}
        </p>

        <p class="text-red-500">
          {{ props.resource.length }}
        </p>
      </div>

      <font-awesome-icon
        :icon="arrow"
        class="ml-5"
      />
    </div>

    <transition
      appear
      @enter="hideResourceOverflow"
      @after-enter="showResourceOverflow"
      @leave="hideResourceOverflow"
    >
      <div
        v-show="resourceDisplayed"
        :class="`overflow-y-scroll ${resourceDisplayed ? 'h-44' : ''}`"
      >
        <div
          ref="resourceWrapper"
        >
          <app-card-list
            v-if="props.resource.length > 0"
            :list="props.resource"
            :component="props.component"
            :shadow-classes="`shadow-none`"
            :y-margins="yMargins"
          />
        </div>
      </div>
    </transition>
  </app-card-item>
</template>

<script setup>
import {
  computed,
  ref,
} from 'vue'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppCardList from '@shared/components/ui/card/AppCardList.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display resource on loading
  displayResource: {
    type: Boolean,
    default: false,
  },
  // Display title
  title: {
    type: String,
    default: '',
  },
  // Component used for AppCardList
  component: {
    type: Object,
    default: () => ({}),
  },
  // Padding
  padding: {
    type: String,
    default: 'p-5',
  },
  // Font size
  fontSize: {
    type: String,
    default: 'text-lg',
  },
  // Vertical margins
  // between cards
  yMargins: {
    type: String,
    default: 'my-4',
  },
})

const resourceDisplayed = ref(props.displayResource)
const resourceWrapper = ref() // Template ref

const arrow = computed(() => (
  resourceDisplayed.value
    ? 'chevron-up'
    : 'chevron-down'
))

// Hide or not the resource overflow
// It must not be displayed during resource transition
const resourceOverflowHidden = ref(resourceDisplayed.value)

function toggleResourceDisplayed() {
  resourceDisplayed.value = !resourceDisplayed.value
}

function hideResourceOverflow() {
  resourceOverflowHidden.value = true
}

function showResourceOverflow() {
  resourceOverflowHidden.value = false
}
</script>
