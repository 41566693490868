export default function useCookies() {
  /**
   * Cookie management name
   *
   * @type {string}
   */
  const cookieName = 'tarteaucitron'

  /**
   * Get setting value in cookie
   *
   * @param setting
   *
   * @returns {*|string}
   */
  function getCookieSetting(setting) {
    const values = parseCookie()

    return (values[`c_${setting}`] === 'true' ?? false)
  }

  /**
   * Parse cookie values to an array
   *
   * @returns {*[]}
   */
  function parseCookie() {
    const values = []

    // Split each key / value pair
    const cookie = getCookieValue()
      .split('!')
      ?.slice(1)

    // Parse each pair to make
    // it readable in an array
    cookie.forEach((c) => {
      const tmp = c.split('=')
      values[tmp[0]] = tmp[1]
    })

    return values
  }

  /**
   * Retrieve cookie value by name
   *
   * @param name
   *
   * @returns {*|string}
   */
  function getCookieValue(name = cookieName) {
    return document.cookie
      .match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)
      ?.pop() || ''
  }

  return {
    getCookieSetting,
  }
}
