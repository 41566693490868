/* eslint-disable import/prefer-default-export */
import i18n from '@shared/i18n/vue-i18n'

/**
 * Check if a translation exists for a specific locale
 *
 * @param {string} key
 * @param {object} locale
 *
 * @returns {boolean}
 */
export const translationExists = (key, locale) => i18n.global.t(key, {}, { locale: locale?.value || null }) !== key
