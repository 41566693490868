<template>
  <draggable
    :list="innerValue"
    item-key="id"
    :group="draggableGroup"
    handle=".sortable-list-handle"
    animation="200"
    :class="classes"
    @start="handleFocus(true)"
    @end="handleFocus(false)"
    @change="handleSort"
  >
    <template
      #item="{element, index}"
    >
      <div
        v-if="!element._destroy"
        class="my-4 flex items-center"
      >
        <app-card-item class="flex-1 flex items-center justify-between flex-1-min-w-0">
          <div class="flex-1-min-w-0">
            <slot
              name="control-vue-draggable-item"
              v-bind="{ element, index }"
            />
          </div>

          <span class="mx-3">
            <app-icon
              library="fontawesome"
              class="text-red-500 cursor-pointer"
              name="minus"
              size="lg"
              @click="handleRemove(element)"
            />
          </span>
        </app-card-item>

        <app-icon
          library="coolicon"
          name="hamburger"
          class="ml-3 sortable-list-handle cursor-move"
          size="text-3xl"
        />
      </div>
    </template>
  </draggable>

  <slot
    v-if="displayableElements.length === 0"
    name="control-vue-draggable-no-item"
  />
</template>

<script setup>
import { computed } from 'vue'
import draggable from 'vuedraggable'

import useFormControl from '@shared/hooks/form/formControl'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Value used to fill inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
  // Draggable component's "group" props
  draggableGroup: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })

function handleSort() {
  innerValue.value.forEach((item, index) => {
    item.position = index
  })
}

function handleRemove(removedItem) {
  removedItem._destroy = true
}

// Ignore elements marked for destroy
const displayableElements = computed(() => (
  innerValue.value?.filter((item) => (
    !item._destroy
  )) || []
))
</script>
