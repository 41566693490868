<template>
  <div
    ref="wrapperEl"
    :class="cardStyle"
  >
    <slot />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  // CSS for card background
  backgroundClasses: {
    type: String,
    default: 'bg-white',
  },
  // CSS for card borders
  borderClasses: {
    type: String,
    default: 'rounded-2lg',
  },
  // CSS for card shadow
  shadowClasses: {
    type: String,
    default: 'shadow-lg shadow-theme-500/10',
  },
  // CSS for card height
  heightClasses: {
    type: String,
    default: '',
  },
})

const wrapperEl = ref()

// ---------- STYLE ----------

const cardStyle = computed(() => {
  const classes = []

  classes.push(props.backgroundClasses)
  classes.push(props.borderClasses)
  classes.push(props.shadowClasses)
  classes.push(props.heightClasses)

  return classes.join(' ')
})
</script>
