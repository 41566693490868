<template>
  <div class="truncate">
    <a
      v-if="link"
      :class="`app-link ${light ? 'app-light-link' : ''}`"
      :href="`${refPrefix ? refPrefix : ''}${link}`"
      :target="!refPrefix ? '_blank' : '_self'"
    >
      {{ text ?? link }}
    </a>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // Link type (mailto, tel...)
  type: {
    type: String,
    default: 'url',
  },
  // Link to use (URL, phone no...)
  link: {
    type: String,
    default: null,
  },
  // Text to replace url
  text: {
    type: String,
    default: null,
  },
  // Set light link
  light: {
    type: Boolean,
    default: false,
  },
})

const refPrefix = computed(() => {
  switch (props.type) {
    case 'phone':
      return 'tel:'
    case 'email':
      return 'mailto:'
    default:
      return null
  }
})
</script>
