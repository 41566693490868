<template>
  <div>
    <app-button
      :icon-props="{
        library: 'coolicon',
        name: 'info_circle_outline',
        size: 'text-2xl'
      }"
      hide-label
      :hide-icon="false"
      emphasis="low"
      font-color-classes="text-theme-500"
      width="8"
      height="8"
      padding="p-0"
      class="leading-none"
      @click="handleOpen"
    />

    <!-- Modal -->
    <app-overlay
      :show="modalDisplayed"
      @clicked="handleClose"
    >
      <app-modal @closed="handleClose">
        <slot />
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'

const modalDisplayed = ref(false)

function handleOpen() {
  modalDisplayed.value = true
}

function handleClose() {
  modalDisplayed.value = false
}
</script>
