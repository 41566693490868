<template>
  <slot />

  <div class="mt-6 flex items-center justify-center">
    <app-button
      v-if="showCancelButton"
      class="mr-12"
      color="gray"
      :label="cancelTextToUse"
      emphasis="low"
      @click="handleCancel"
    />

    <app-button
      :disabled="disableConfirmButton"
      :color="confirmColor"
      :label="confirmTextToUse"
      :loading="confirmLoading"
      @click="handleConfirm"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // Text displayed
  // in confirm button
  confirmText: {
    type: String,
    default: null,
  },
  // Confirm loading or not
  confirmLoading: {
    type: Boolean,
    default: false,
  },
  // Text displayed
  // in cancel button
  cancelText: {
    type: String,
    default: null,
  },
  // Confirm button's color
  confirmColor: {
    type: String,
    default: 'theme',
  },
  // Disable confirm button
  disableConfirmButton: {
    type: Boolean,
    default: false,
  },
  // Show cancel button
  showCancelButton: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits([
  'cancelled',
  'confirmed',
])

const { t } = useI18n()

function handleCancel() {
  emits('cancelled')
}

function handleConfirm() {
  emits('confirmed')
}

const confirmTextToUse = computed(() => (
  props.confirmText ?? t('common.actions.confirm')
))

const cancelTextToUse = computed(() => (
  props.cancelText ?? t('common.actions.cancel')
))
</script>
