<template>
  <!-- A root div is needed,to avoid multiple labels to be considered as flex children from parent component, or inherit useless styles -->
  <div :class="`${classes} ${wrapperClasses}`">
    <label
      v-for="(option, i) in options"
      :key="i"
      :class="optionClasses(option)"
    >
      <input
        v-model="innerValue"
        type="radio"
        :name="name"
        :required="required"
        :value="option.value ? option.value : option"
        class="radio-button relative appearance-none align-middle w-4 h-4 rounded-full border-1 border-gray-400 bg-white
          checked:after:bg-theme-500 checked:after:w-2.5 checked:after:h-2.5 checked:after:rounded-1/2
          checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2
          checked:border-theme-500"
        :disabled="option.disabled"
        @focus="handleFocus(true)"
        @blur="handleFocus(false)"
      >
      <span class="ml-2 text-sm font-bold">
        {{ option.label ? option.label : option }}
      </span>

      <form-control-hint
        v-if="option.hint"
        :hint="option.hint"
      />
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useFormControl from '@shared/hooks/form/formControl'
import FormControlHint from '@shared/components/form/FormHint.vue'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Options available
  // (for select, radio...)
  options: {
    type: Array,
    default: () => ([]),
  },
  // Options list's direction ("vertical" or "horizontal")
  optionsDirection: {
    type: String,
    default: 'vertical',
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  handleFocus,
} = useFormControl(props, { emits })

const wrapperClasses = computed(() => {
  switch (props.optionsDirection) {
    case 'horizontal':
      return 'flex flex-row'
    case 'vertical':
    default:
      return 'flex flex-col'
  }
})

function optionClasses(option) {
  const classes = []

  switch (props.optionsDirection) {
    case 'horizontal':
      classes.push('mx-1')
      break
    case 'vertical':
    default:
      classes.push('my-1')
  }

  if (option.disabled) {
    classes.push('opacity-50', 'cursor-not-allowed')
  }

  return classes
}
</script>
