import { computed } from 'vue'
import { useStore } from 'vuex'
import { fromJS } from 'immutable'

/**
 * @param {string} type which filter's type (excursions, pointsofinterest...)
 * @param {object} form VeeValidate form
 * @param {object} options emits function, ...
 * @returns {object}
 */
export default function useFilters(type, form = null, options = {}) {
  const store = useStore()

  function handleFilterChange(name, value) {
    // Sync form filters values with store
    store.commit('filters/SET_FILTER', { type, name, value })
  }

  function handleFiltersApplied() {
    options.emits('filters-applied')
  }

  function handleResetFilters() {
    if (form) {
      // Apply default filters' values to each form value
      Object.entries(form.values).forEach(([name]) => {
        form.setFieldValue(name, defaultFilters.value[name])
      })
    }
    handleFiltersApplied()
  }

  // Retrieve default filters' values copy from store
  const defaultFilters = computed(() => (
    fromJS(store.state.filters.default[type]).toJS()
  ))

  // Filter's active if there is at least 1 filter different from default
  const hasActiveFilters = computed(() => (
    store.getters['filters/getFiltersCount'](type) > 0
  ))

  return {
    handleFilterChange,
    handleFiltersApplied,
    handleResetFilters,
    hasActiveFilters,
  }
}
