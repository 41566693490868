<template>
  <div class="flex space-x-4 mt-4">
    <share-action
      :resource="resource"
      :emphasis="'low'"
      :hide-icon="false"
      hide-text
      :big-icons="bigIcons"
    />

    <router-link
      v-if="resource.authorizations?.update"
      v-slot="{ navigate }"
      custom
      :to="{ name: 'edit-excursions', params: { id: resource.id } }"
    >
      <app-button
        emphasis="low"
        :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
        feature="edit"
        :hide-icon="false"
        hide-text
        @click="navigate"
      />
    </router-link>

    <app-button
      v-if="resource.authorizations?.delete"
      emphasis="low"
      :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
      feature="delete"
      :hide-icon="false"
      hide-text
      @click="handleDestroy"
    />

    <app-overlay
      :show="deleteConfirmModalDisplayed"
      @clicked="handleDestroyCancel"
    >
      <app-modal @closed="handleDestroyCancel">
        <app-confirm
          confirm-color="red"
          @cancelled="handleDestroyCancel"
          @confirmed="handleDestroyConfirm"
        >
          <p>
            {{ t(
              'excursions.destroy.confirm',
              { excursion_name: getAttributeTranslation(resource.attributes.title) }
            ) }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import ShareAction from '@app/components/resources/excursion/actions/ShareAction.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on
  // delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'destroyed',
])

const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

const deleteConfirmModalDisplayed = ref(false)

function handleDestroy() {
  deleteConfirmModalDisplayed.value = true
}

function handleDestroyConfirm() {
  deleteConfirmModalDisplayed.value = false
  emits('destroyed')
  props.destroyCallback()
}

function handleDestroyCancel() {
  deleteConfirmModalDisplayed.value = false
}
</script>
