import { createI18n } from 'vue-i18n'

function fetchLocales() {
  const locales = import.meta.glob('../../../../lang/*.json', {
    eager: true,
  })

  const messages = {}

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const path in locales) {
    const locale = path
      .split('/')
      .pop()
      .replace('.json', '')

    messages[locale] = locales[path].default
  }

  return messages
}

const locales = fetchLocales()

// Auto-replace default VueJS keys by Laravel translation keys.
function transformMessages(messages) {
  return JSON.parse(
    JSON.stringify(messages).replace(/:([a-zA-Z0-9_]+)/g, '{$1}'),
  )
}
export default createI18n({
  legacy: false, // use Composition API only
  locale: localStorage.getItem('locale') || navigator.language.split('-')[0] || import.meta.env.VITE_DEFAULT_LOCALE, // use browser locale, or default one
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE, // fallback locale
  messages: transformMessages(locales),
  warnHtmlMessage: false, // avoid warning for HTML in translations files
})
