<template>
  <i
    v-if="library === 'fontawesome'"
    :class="classes.join(' ')"
  >
    <font-awesome-icon
      :icon="faIcon"
      :size="size"
      :title="title"
      @click="emits('click')"
    />
  </i>

  <i
    v-else-if="library === 'coolicon'"
    :class="`coolicon ${cooliconClasses}`"
    :title="title"
    @click="emits('click')"
  />

  <!-- eslint-disable vue/no-v-html -->
  <i
    v-else-if="library === 'local'"
    :class="`${localSvgClasses}`"
    @click="emits('click')"
    v-html="svgContent"
  />
  <!--eslint-enable-->
</template>

<script setup>
import {
  computed, ref, toRefs, watch,
} from 'vue'

const props = defineProps({
  // Which library icon to use
  // Available values : "fontawesome", "coolicons"
  library: {
    type: String,
    default: 'fontawesome',
  },
  // Icon's size
  // If fontawesome's icon : https://www.npmjs.com/package/@fortawesome/vue-fontawesome (look for size prop)
  // If coolicon's icon: https://tailwindcss.com/docs/font-size
  // If local icon : https://tailwindcss.com/docs/width
  size: {
    type: String,
    default: null,
  },
  // Icon's name
  // If fontawesome's icon : https://fontawesome.com/v5.15/icons
  // If coolicon's icon: https://coolicons.cool/
  name: {
    type: String,
    required: true,
  },
  // Icon's name
  // If fontawesome's icon : available values : "fas", "fab"
  // If coolicon's icon: not used
  iconStyle: {
    type: String,
    default: null,
  },
  // Icon's title, to describe it when hovering it
  title: {
    type: String,
    default: '',
  },
  // More classes for icons
  classes: {
    type: Array,
    default: () => ([]),
  },
})

const emits = defineEmits([
  'click',
])

// ---------- FONTAWESOME ----------

// use solid as default
const fontAwesomeStyle = computed(() => (
  props.iconStyle || 'fas'
))

// used for font-awesome-icon's component's icon prop
const faIcon = computed(() => (
  [fontAwesomeStyle.value, props.name]
))

// ---------- COOLICONS ----------

const cooliconClasses = computed(() => {
  const classes = []

  classes.push(`ci-${props.name}`)

  // font size
  if (props.size) {
    classes.push(props.size)
  } else {
    // use a default size, to keep proportions with other icons
    switch (props.name) {
      case 'plus':
        classes.push('text-2.5xl')
        break
      default:
        classes.push('text-xl')
    }
  }

  return classes
    .concat(props.classes)
    .join(' ')
})

// ---------- LOCAL SVG ----------

const svgContent = ref()

buildSvgContent()

const { library } = toRefs(props)

watch(library, () => {
  buildSvgContent()
})

function buildSvgContent() {
  if (props.library === 'local') {
    fetch(`/images/shared/svg_icons/${props.name}.svg`)
      .then((response) => {
        if (response.status === 200) {
          response.text()
            .then((text) => {
              svgContent.value = text
            })
        }
      })
  }
}

const localSvgClasses = computed(() => {
  const classes = []
  classes.push('local-svg-container', 'inline-block')

  // width
  if (props.size) {
    classes.push(props.size)
  } else {
    // use a default size, to keep proportions with other icons
    classes.push('w-3')
  }

  return classes
    .concat(props.classes)
    .join(' ')
})
</script>

<style lang="scss">
// Style is not scoped, otherwise it's not applied to "svg" by "v-html"
.local-svg-container svg {
  @apply h-auto w-full;
}
</style>
