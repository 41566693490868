<template>
  <!-- Active traveller folder -->
  <traveller-folder-active
    v-if="store.state.folders.activeFolder && !activeFolderLoading"
    :key="store.state.folders.activeFolder.id"
    :resource="store.state.folders.activeFolder"
    class="mb-4"
  />

  <!-- Categories -->
  <app-slidable-list
    v-if="!guestTravellerAccess"
    :title="t('dashboard.points_of_interest.categories')"
    :list="categoriesSlidableList"
    :loading="categoriesLoading"
    :component="PointOfInterestCategoryCard"
    :empty-component="PointOfInterestCategoryCardEmpty"
    item-min-width="1/4"
    @item-clicked="handleCategoryClick"
  />

  <!-- Shortcuts -->
  <router-link
    v-if="!guestTravellerAccess"
    :to="{ name: 'index-points-of-interest' }"
  >
    <app-link-card
      :label="t('dashboard.links.closest_pois')"
    />
  </router-link>

  <router-link
    :to="{ name: 'index-excursions' }"
  >
    <app-link-card
      :label="t('dashboard.links.excursions')"
    />
  </router-link>

  <router-link
    v-if="!guestTravellerAccess"
    :to="{ name: 'index-shares' }"
  >
    <app-link-card
      :label="t('dashboard.links.shares')"
    />
  </router-link>

  <!-- Pending traveller contacts -->
  <app-pending-card
    v-if="canAccess"
    :resource="pendingContacts"
    :title="t('users_relationships.index.pending_requests')"
    :component="UsersRelationshipCard"
    padding="p-4"
    font-size="text-md"
    y-margins="my-0"
  />

  <!-- Favorites excursions -->
  <app-slidable-list
    v-if="favoritesList.length > 0"
    :title="t('dashboard.excursions.favorites')"
    :list="favoritesList"
    :component="ExcursionCard"
    :loading="favoritesLoading"
    item-min-width="7/10"
    margin-top="mt-2"
  />

  <!-- Share profile -->
  <app-card-item
    v-if="!guestTravellerAccess"
    class="flex justify-center items-center p-4 mt-2"
  >
    <app-rounded-picture
      :picture-url="avatarUrl"
      with-shadow
    />

    <app-button
      class="ml-6"
      :label="t('users.profile.share_profile')"
      @click="handleQrModalDisplay"
    />
  </app-card-item>

  <cookies-warning-modal
    v-if="!getCookieSetting('gmaps')"
  />

  <app-qr-code-modal
    v-if="!guestTravellerAccess"
    :show="qrModalDisplayed"
    @closed="handleQrModalCancel"
  />

  <call-to-register
    v-if="guestTravellerAccess"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import {
  fetchFavorites as apiFetchFavorites,
  fetchPendingContacts as apiFetchPendingContacts,
  fetchPointOfInterestCategories as apiFetchPointOfInterestCategories,
} from '@shared/http/api'
import useAccess from '@extranet/hooks/access'
import useCookies from '@shared/hooks/cookies'
import useFilters from '@shared/hooks/filters'
import useResource from '@shared/hooks/resources/resource'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppLinkCard from '@app/components/ui/AppLinkCard.vue'
import AppPendingCard from '@app/components/ui/AppPendingCard.vue'
import AppQrCodeModal from '@app/components/features/qr_code/AppQrCodeModal.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppSlidableList from '@app/components/ui/AppSlidableList.vue'
import CallToRegister from '@app/components/resources/guest/CallToRegister.vue'
import CookiesWarningModal from '@shared/components/cookies/CookiesWarningModal.vue'
import ExcursionCard from '@app/components/resources/excursion/ExcursionCard.vue'
import PointOfInterestCategoryCard from '@app/components/resources/point_of_interest_category/PointOfInterestCategoryCard.vue'
import PointOfInterestCategoryCardEmpty from '@app/components/resources/point_of_interest_category/PointOfInterestCategoryCardEmpty.vue'
import TravellerFolderActive from '@app/components/resources/traveller_folder/TravellerFolderActive.vue'
import UsersRelationshipCard from '@app/components/resources/users_relationship/UsersRelationshipCard.vue'

const store = useStore()
const { t } = useI18n()
const { getImageUrlFromResource } = useResource()
const { guestTravellerAccess } = useAccess()
const { getCookieSetting } = useCookies()

// ---------- FAVORITES ----------

const favoritesList = ref([])
const favoritesLoading = ref(false)

function fetchFavorites() {
  favoritesLoading.value = true

  const params = {
    limit: 5,
    paginate: false,
    source: 'app',
    use_geoloc_setting: true,
  }

  apiFetchFavorites(params)
    .then((response) => {
      favoritesList.value = response.data.data?.map((favorite) => ({
        resource: favorite,
        hideActions: true,
      })) ?? []
    })
    .finally(() => {
      favoritesLoading.value = false
    })
}

// ---------- ACTIVE TRAVELLER FOLDER ----------

const activeFolderLoading = ref(false)

function fetchTravellerFolder() {
  activeFolderLoading.value = true

  store
    .dispatch('folders/getActiveFolder')
    .finally(() => {
      activeFolderLoading.value = false
    })
}

// ---------- PENDING CONTACTS ----------

const pendingContacts = ref([])
const pendingContactsLoading = ref(false)

function fetchPendingContacts() {
  pendingContactsLoading.value = true

  apiFetchPendingContacts()
    .then((response) => {
      pendingContacts.value = response.data.data?.map((contact) => ({
        resource: contact,
        updateCallback: () => fetchPendingContacts(),
      })) ?? []
    })
    .finally(() => {
      pendingContactsLoading.value = false
    })
}

function fetchTraveller() {
  fetchTravellerFolder()
}

function fetchData() {
  fetchFavorites()
  fetchTraveller()

  if (!guestTravellerAccess.value) {
    fetchPendingContacts()
  }
  fetchCategories()
}

// ---------- AVATAR ----------

const avatarUrl = computed(() => (
  getImageUrlFromResource(store.state.auth.user, 'xs')
))

// ---------- QR CODE ----------

const qrModalDisplayed = ref(false)

function handleQrModalDisplay() {
  qrModalDisplayed.value = true
}

function handleQrModalCancel() {
  qrModalDisplayed.value = false
}

// ---------- CATEGORIES ----------

const router = useRouter()
const { handleFilterChange } = useFilters('pointsOfInterest', {}, {})

const categories = ref([])
const categoriesLoading = ref(false)

function fetchCategories() {
  categoriesLoading.value = true

  apiFetchPointOfInterestCategories()
    .then((response) => {
      categories.value = response.data.data ?? []
    })
    .finally(() => {
      categoriesLoading.value = false
    })
}

function handleCategoryClick(newSelectedCategory) {
  // Updating filter
  handleFilterChange('point_of_interest_category_id', newSelectedCategory?.resource.id)

  // Redirect
  router.replace({ name: 'index-points-of-interest' })
}

// Slidable list prop
const categoriesSlidableList = computed(() => (
  // Wrap each category in a "resource" attribute
  categories.value.map((category) => ({
    resource: category,
  }))
))

// ---------- ACCESS ----------

const canAccess = computed(() => (
  pendingContacts.value.length > 0
    && !pendingContactsLoading.value
    && guestTravellerAccess.value
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchData()
})
</script>
