<template>
  <input
    :id="name"
    v-model="innerValue"
    type="hidden"
    :name="name"
  >
</template>

<script setup>
import useFormControl from '@shared/hooks/form/formControl'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
})

const emits = defineEmits([
  'changed',
])

const { innerValue } = useFormControl(props, { emits })
</script>
